
<template>
  <div class="d-flex align-items-center min-vh-100">
    <CContainer fluid v-if="ventana == 1">
      <CRow class="justify-content-center">
        <CCol class="col-5">
          <CCardGroup>
            <CCard class="mx-4 mb-0">
              <CCardBody class="p-4">
                <CForm validate @submit="generarCodigosActivacion">
                  <h1>Restablece tu Contraseña</h1>
                  <template>
                    <CAlert
                      :show.sync="dismissCountDown"
                      closeButton
                      color="danger"
                      fade
                    >
                      <span v-html="mensajeError"></span>
                      <strong
                        >Borrando Mensaje en {{ dismissCountDown }}</strong
                      >
                      segundos...
                    </CAlert>
                  </template>
                  <CInput
                    label="Email"
                    autocomplete="off"
                    placeholder="Ingresa tu correo"
                    minlength="5"
                    type="email"
                    required
                    was-validated
                    v-model="form.email"
                  >
                  <template #prepend-content
                      ><CIcon name="cil-user"
                    /></template>
                  </CInput>
                  <hr />
                  <CButton color="success" type="submit" block
                    >Restablecer</CButton
                  >
                </CForm>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
    <CContainer fluid v-if="ventana == 2">
      <CRow class="justify-content-center">
        <CCol class="col-10">
          <CCardGroup>
            <CCard class="mx-4 mb-0">
              <CCardBody class="p-4">
                <CForm validate @submit="enviarCodigos">
                  <h3>Codigo para verificar tu identidad</h3>
                  <template>
                    <CAlert
                      :show.sync="dismissCountDown"
                      closeButton
                      color="danger"
                      fade
                    >
                      <span v-html="mensajeError"></span>
                      <strong
                        >Borrando Mensaje en {{ dismissCountDown }}</strong
                      >
                      segundos...
                    </CAlert>
                  </template>
                  <br />
                  <CInput
                    label="Codigo Enviado Por Email"
                    autocomplete="off"
                    minlength="3"
                    maxlength="6"
                    v-model="form_codigos.codigo_email"
                    required
                    was-validated
                  >
                  </CInput>
                  <hr />
                  <CButton color="success" type="submit" block
                    >Restablecer
                  </CButton>

                  <hr />
                  <h6>
                    Si escribiste incorrectamente el correo, haz
                    <a @click="modificarFormulario" class="text-primary"
                      >Click Aqui</a
                    >
                    y registrate nuevamente
                  </h6>
                </CForm>
              </CCardBody>
            </CCard>
            <CCard
              color="info"
              text-color="white"
              class="text-center py-5 d-md-down-none"
              body-wrapper
            >
              <CCardBody>
                <h5 class="text-justify">
                  Hemos enviado un mensaje a tu correo con el codigo de validacion para restablecer tu 
                  contraseña
                </h5>
                <p class="text-justify">
                  <strong>Email:</strong> {{ this.form.email }}
                </p>
                <h6 class="text-justify">
                  Ingresalos a continuacion para restablecer la contraseña
                </h6>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
    <CContainer fluid v-if="ventana == 3">
      <CRow class="justify-content-center">
        <CCol class="col-10">
          <CCardGroup>
            <CCard class="mx-4 mb-0 text-center py-5" color="success" text-color="white">
              <CCardBody class="p-4">
                <h1>Tu Contraseña se ha restablecido con exito</h1>
                <br />
                <h4>
                  Esta es tu nueva contraseña: <strong>{{clave}}</strong> , tambien fue enviada a tu correo
                </h4>
                <p>Da click 
                  <router-link :to="'/login'">
                        Aqui
                    </router-link>
                 para que inicies sesion</p>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
    <div
      :style="
        'width: 100%;height: 100%;top: 0;left: 0;position: fixed;display: flex;opacity: 0.3;background-color: #fff;z-index: 10000;text-align: center;justify-content: center;align-items: center;visibility:' +
        visibleLoading
      "
    >
      <CSpinner style="width: 8rem; height: 8rem" color="info" grow />
    </div>
  </div>
</template>

<script>
export default {
  name: "OlvideClave",
  data() {
    return {
      errors: [],
      errorCodigos: [],
      ventana: 1,
      form: {
        email: "",
      },
      form_codigos: {
        codigo_email: "",
      },
      clave:'',
      mensajeError: "",
      dismissCountDown: 0,
    };
  },
  computed: {
    isDisabled: function () {
      return !this.form.condiciones;
    },
    visibleLoading() {
      return this.$store.state.auth.loading_http;
    },
  },
  methods: {
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        //if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    generarCodigosActivacion: function (e) {
      e.preventDefault();
      //this.ventana = 2;
      this.$store.dispatch("auth/loading_http", "visible");
      this.$http
        .post("/sms/restablecer/clave/regitrar", {
          email: this.form.email,
        })
        .then((response) => {
          if (response.data.status == "OK") {
            this.ventana = 2;
          } else {
            let email = response.data.email;
            let mensajeError = "";
            if (email != undefined) {
              mensajeError += `* ${email} <br>`;
            }
            if (
              response.data.status == "ERROR1" ||
              response.data.status == "ERROR2"
            ) {
              mensajeError = response.data.mensaje_email + " <br>";
            }

            if (mensajeError == "") {
              mensajeError =
                "Ocurrio Un Error En Nuestra Plataforma Por Favor Intentar Mas Tarde <br>";
            }

            this.mensajeError = mensajeError;
            this.dismissCountDown = 60;
          }
        })
        .catch((error) => {
          let mensajeError = "";
          if (error.response) {
            let dataError = error.response.data;
            if (error.response.status == 422) {
              mensajeError = `${dataError.message} \n`;
              let errors = dataError.errors;
              for (const key in errors) {
                if (Object.hasOwnProperty.call(errors, key)) {
                  const element = errors[key];
                  for (const iterator of element) {
                    mensajeError += `${iterator} \n`;
                  }
                }
              }
              this.mensajeError = mensajeError;
              this.dismissCountDown = 60;
            } else {
              this.mensajeError =
                "Ocurrio Un Error En Nuestra Plataforma Por Favor Intentar Mas Tarde <br>";
              this.dismissCountDown = 60;
            }
          }
        })
        .finally(() => this.$store.dispatch("auth/loading_http", "hidden"));
    },
    modificarFormulario(e) {
      this.ventana = 1;
      this.form = this.$store.state.crearcuentasms.formularioRegistrar;
      e.preventDefault();
    },
    enviarCodigos(e) {
      e.preventDefault();
      //this.ventana = 2;
      this.$store.dispatch("auth/loading_http", "visible");
      this.$http
        .post("/sms/restablecer/clave/validar", {
          codigo_email:this.form_codigos.codigo_email,
          email: this.form.email,
        })
        .then((response) => {
          if (response.data.status == "OK") {
            this.clave = response.data.mensaje;
            this.ventana = 3;
          } else if (response.data.status == "ERROR") {
            let mensajeError = "";
            if (response.data.mensaje_codigo_email == "ERROR") {
              mensajeError += `* El Codigo Ingresado Es Incorrecto <br>`;
            }
            if (mensajeError == "") {
              mensajeError =
                "Ocurrio Un Error En Nuestra Plataforma Por Favor Intentar Mas Tarde <br>";
            }
            this.mensajeError = mensajeError;
            this.dismissCountDown = 60;
          }
        })
        .catch(() => {
          this.mensajeError =
            "Ocurrio Un Error En Nuestra Plataforma Por Favor Intentar Mas Tarde <br>";
          this.dismissCountDown = 60;
        })
        .finally(() => this.$store.dispatch("auth/loading_http", "hidden"));
    },
    calcTime(city, offset) {
      // creamos el objeto Date (la selecciona de la máquina cliente)
      let d = new Date();
      // lo convierte  a milisegundos
      // añade la dirferencia horaria
      // recupera la hora en formato UTC
      let utc = d.getTime() + d.getTimezoneOffset() * 60000;
      // crea un nuevo objeto Date usando la diferencia dada.
      let nd = new Date(utc + 3600000 * offset);
      try {
        let hora = nd.toTimeString().substr(0, 8);
        let fecha = nd.toLocaleString().split(" ")[0];
        fecha = fecha.replace(",", "");
        let tipo = "a. m.";
        //console.log(fecha);
        if (
          parseInt(hora.split(":")[0]) >= 12 &&
          parseInt(hora.split(":")[0]) <= 23
        ) {
          tipo = "p. m.";
        }
        let nueva_fecha = `${fecha}, ${hora} ${tipo}`;
        //console.log(nd.toLocaleString());
        return nueva_fecha;
      } catch (error) {
        return nd.toLocaleString();
      }
    },
    validarFueraDeHorario() {
      let fechaColombia = this.calcTime("Bogota", "-5");
      //console.log(fechaColombia);
      //let fechaActual = new Date().toLocaleString();
      //let horaActual = fechaActual.split(" ")[1];
      let horaColombia = fechaColombia.split(" ")[1];
      //let tiempoActual = fechaActual.split(" ")[2];
      let tiempoColombia = fechaColombia.split(" ")[2];
      horaColombia = horaColombia.trim();
      //horaColombia = '3:59:59'
      //tiempoColombia = 'p. m.'
      horaColombia = this.convertirHora24X12(horaColombia);
      //console.log(horaColombia);
      //let calcula = horaColombia.split(":")[0];
      //console.log(calcula);
      //horaColombia = calcula >= 1 && calcula <= 9 ? '0'+horaColombia : horaColombia;
      //console.log(horaColombia);
      //horaColombia = '14:10:59'
      //tiempoColombia = 'a. m.'
      horaColombia = horaColombia.substr(0, 5);
      //console.log(horaColombia);
      //console.log(tiempoColombia);
      if (
        (horaColombia >= "07:00" &&
          horaColombia <= "11:59" &&
          tiempoColombia == "a. m.") ||
        (horaColombia >= "12:00" &&
          horaColombia <= "12:59" &&
          tiempoColombia == "p. m.") ||
        (horaColombia >= "01:00" &&
          horaColombia <= "06:59" &&
          tiempoColombia == "p. m.")
      ) {
        this.ventana = 1;
        return "OK";
      } else {
        this.ventana = 1; //this.ventana = 3;
        return "OK";
      }
    },
    convertirHora24X12(hora) {
      let calcula = hora.split(":")[0];
      if (calcula >= 13 && calcula <= 23) {
        calcula = calcula - 12;
        let horamod = hora.split(":");
        horamod[0] = calcula;
        hora = horamod.join(":");
      }

      return hora;
    },
  },
  mounted() {
    setTimeout(() => {
      this.ventana = 1;
    }, 100);
  },
};
</script>

